import numeral from 'numeral';
import { useEffect, useState } from 'react';
import Image from 'src/components/image';
import dayjs from 'dayjs';
import 'dayjs/locale/th';
import { AiOutlineClockCircle, AiOutlineEye } from 'react-icons/ai';
import { RiPlayCircleLine } from 'react-icons/ri';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import { incrementCountview } from 'src/utils/increment-countview';
import { sendDataLayerGlobal } from 'src/utils/datalayer/send-datalayer';
import { NEXT_PUBLIC_DEFAULT_COUNT_VIEW } from 'src/constants';
dayjs.extend(buddhistEra);

const CardH = ({ data, large = false, description = false, withMB = null, txtStyle = false, external = false, eyeBall = true, eventDataLayer, heading, showFooter = true, isVideo = false, showReadmore = false }) => {
  const [countView, setCountView] = useState(0);

  useEffect(() => {
    incrementCountview(data)
      .then(item => setCountView(item))
      .catch(() => setCountView(0));
  }, []);

  const categoryNameTH = data?.subCategory?.th ? data?.subCategory?.th : data?.section?.th;
  const dateUpdate = dayjs(data.published_at).locale('th').format('DD MMM BBBB | H:mm');
  // console.log(data);
  return (
    <>
      {external ? (
        <a
          href={data.link}
          //! DATALAYER
          target='_blank'
          rel={'noopener noreferrer'}
          className='card-wrapper'
          onClick={() => sendDataLayerGlobal({ ...eventDataLayer })}
        >
          <div className={`card-h ${withMB === null ? '' : withMB}`}>
            <div className={`card-h-thumb ${large ? '--large' : ''}`}>
              <Image image={data.image} title={data.title} external={external} type={large ? 'CARD_MEDIUM' : 'CARD_SMALL'} />
              {
                isVideo && (
                  <div className='icon'>
                    <RiPlayCircleLine />
                  </div>
                )
              }
            </div>
            <div className={`card-h-content ${large ? '--large' : ''}`}>
              <h3 className={`card-h-content-title ${txtStyle ? '--light' : ''} text-excerpt-2`}>{data.title}</h3>
              {
                showReadmore && (
                  <div className='read-more'>
                    <span>อ่านต่อ...</span>
                  </div>
                )
              }
              {showFooter && (
                <div className={`card-h-content-meta ${txtStyle ? '--small' : ''}`}>
                  <AiOutlineClockCircle />
                  <span>{dateUpdate}</span>
                </div>
              )}
              {description && <p className='text-excerpt-3'>{data.blurb}</p>}
            </div>
          </div>
        </a>
      ) : (
        <a
          href={data?.link}
          className='card-wrapper'
          //! DATALAYER
          onClick={() => sendDataLayerGlobal({ ...eventDataLayer })}
        >
          <div className={`card-h ${withMB === null ? '' : withMB}`}>
            <div className={`card-h-thumb ${large ? '--large' : ''}`}>
              <Image image={data.image} title={data.title} type={large ? 'CARD_MEDIUM' : 'CARD_SMALL'} />
              {data?.section?.th !== null && large && <span className='badge'>{categoryNameTH}</span>}
            </div>
            <div className={`card-h-content ${large ? '--large' : ''}`}>
              <h3 className={`card-h-content-title ${txtStyle ? '--light' : ''} text-excerpt-2`}>{data.title}</h3>
              <div className={`card-h-content-meta ${txtStyle ? '--small' : ''}`}>
                <AiOutlineClockCircle />
                <span className='date'>{dateUpdate}</span>
                {eyeBall && countView > 0 && countView > Number(NEXT_PUBLIC_DEFAULT_COUNT_VIEW) && (
                  <span className='pageview'>
                    <AiOutlineEye />
                    {countView > 999 ? numeral(countView).format('0.0a') : countView}
                  </span>
                )}
              </div>
              {description && <p className='text-excerpt-3'>{data.blurb}</p>}
            </div>
          </div>
        </a>
      )}
    </>
  );
};
export default CardH;
