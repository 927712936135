import dayjs from 'dayjs';
import 'dayjs/locale/th';
import numeral from 'numeral';
import { useEffect, useState } from 'react';
import { AiOutlineClockCircle, AiOutlineEye } from 'react-icons/ai';

import Image from 'src/components/image';

import buddhistEra from 'dayjs/plugin/buddhistEra';
import { incrementCountview } from 'src/utils/increment-countview';
import { sendDataLayerGlobal } from 'src/utils/datalayer/send-datalayer';
import { NEXT_PUBLIC_DEFAULT_COUNT_VIEW } from 'src/constants';

dayjs.extend(buddhistEra);

const CardFull = ({ data, size = '', mb = '', badge = null, date = true, eventDataLayer }) => {
  if (!data) return <div className='default-card-full' />;
  const [countView, setCountView] = useState(0);

  useEffect(() => {
    incrementCountview(data)
      .then(item => setCountView(item))
      .catch(() => setCountView(0));
  }, []);

  const categoryNameTH = data?.subCategory?.th ? data?.subCategory?.th : data?.section?.th;
  const dateUpdate = dayjs(data?.published_at).locale('th').format('DD MMM BBBB | H:mm');
  return (
    <a
      href={data?.link}
      //! DATALAYER
      onClick={() => sendDataLayerGlobal({ ...eventDataLayer })}
    >
      <div className={`card-wrapper ${mb}`}>
        <div className='card-full'>
          <div className={`card-image ${size}`}>
            <Image image={data?.image} title={data?.title} type={size === '--small' ? 'CARD_MEDIUM' : 'CARD_LARGE'} />
          </div>
          <div className='card-content'>
            {data?.section?.th !== null && <span className='card-content-badge'>{badge !== null ? badge : categoryNameTH}</span>}
            <h2 className={`card-content-title ${size}`}>{data?.title}</h2>
            {date && (
              <span className={`card-content-date ${size}`}>
                <AiOutlineClockCircle />
                {dateUpdate}
              </span>
            )}
            {countView > 0 && countView > Number(NEXT_PUBLIC_DEFAULT_COUNT_VIEW) && (
              <span className={`card-content-pageview ${size}`}>
                <AiOutlineEye />
                {countView > 999 ? numeral(countView).format('0.0a') : countView}
              </span>
            )}
          </div>
        </div>
      </div>
    </a>
  );
};
export default CardFull;
